<template>
	<ion-page>
		<ion-content class="ion-padding">
			<div v-if="isDebug">{{ state.step }} - {{  publicId }} - {{  state.uuid }}</div>

			<div v-if="state.activeItemIndex === 0 && !state.submitted && state.found" class="glimpse-cover">
				<NPAttachmentImage
					v-if="state.activeItemIndex === 0 && state?.glimpseAdministration?.survey?.imageAttachment"
					:attachment="state?.glimpseAdministration?.survey?.imageAttachment"
					height="256px"
				/>

				<div class="glimpse-cover-art" v-else-if="state.activeItemIndex === 0 && state.loaded"></div>
				<div class="title">{{ title }}</div>
				<div class="subtitle" v-html="description" />
			</div>

			<div v-if="!state.loaded" class="center-of-page">
				<ion-spinner></ion-spinner>
			</div>

			<div v-else-if="state.loaded && !state.found" style="max-width: 500px; margin: 0 auto">
				<lottie-animation
					ref="doneLottie"
					:animationData="require('@/../public/assets/anim/lottie-not-found.json')"
					:autoPlay="true"
				/>
				<div class="fs-20 ion-text-center">{{ state.notFoundMessage }}</div>
			</div>

			<div v-else-if="state.loaded && state.submitted" class="ion-text-center">
				<div style="max-width: 500px; margin: 0 auto">
					<lottie-animation
						ref="doneLottie"
						:animationData="require('@/../public/assets/anim/glimpse-complete.json')"
						:autoPlay="true"
					/>
				</div>
				<div class="fs-20">Thank you for your feedback!</div>
				<ion-button
					v-if="state.glimpseAdministration.survey.allow_multiple_response_flag"
					fill="outline"
					class="my-8"
					@click="startOver"
				>
					Submit another response
				</ion-button>
			</div>

			<div v-else-if="state.step == 'begin'" class="name-section">
				<div class="name-container">
					<div class="anon-name-label">Your Anonymous Name</div>
					<div class="anon-name">
						<div class="">{{ state.displayName }}</div>
						<i ref="nameGenIconRef" class="fal fa-refresh" style="margin-left:auto;" @click="generateName" />
					</div>
				</div>
				<ion-button style="width:255px;margin-top:8px;" @click="beginGlimpse">Begin</ion-button>
			</div>

			<template v-else-if="state.step == 'glimpse'">
				<div class="glimpse-wrapper">
					<!-- progress bar and indexer -->
					<div v-if="surveyItemLinks.length" class="prog-bar px-4">
						<ion-progress-bar :value="(state.activeItemIndex + 1) / (surveyItemLinks.length)" />
						<div class="prog-bar-controller">
							<div style="min-width: 45px;">
								<ion-button v-show="state.activeItemIndex" fill="none" @click.stop="state.activeItemIndex--">
									<i class="fal fa-chevron-left" />
								</ion-button>
							</div>
							Question {{ `${state.activeItemIndex + 1} of ${surveyItemLinks.length}` }}
							<div style="min-width: 45px;">
								<ion-button v-show="state.activeItemIndex < surveyItemLinks.length - 1" fill="none" @click.stop="state.activeItemIndex++">
									<i class="fal fa-chevron-right" />
								</ion-button>
							</div>
						</div>
					</div>

					<!-- <h1 class="text-4xl font-bold text-white">{{ publicId }}</h1> -->
					<template v-if="activeItem">
						<GlimpseItemRenderer
							v-disable-all="state.disabled"
							v-if="state.found"
							:item="activeItem"
							:values="activeItemResponseValues"
							@selected="save"
						/>
					</template>
					<!-- <pre>
						activeItemLink: {{ activeItemLink.id }}
						responseValues: {{ activeItemResponseValues }}
						response: {{ state.responses[activeItem.id]  }}
						responses: {{ JSON.stringify(state.responses,null,2)  }}
						activeItemLink: {{ activeItemLink }}
						item: {{ activeItem }}
					</pre> -->
				</div>
			</template>


			<div v-else-if="state.step == 'submit'" class="ion-margin ion-text-center">
				<!-- <div style="max-width: 500px; margin: 0 auto">
					<lottie-animation
						ref="doneLottie"
						:animationData="require('@/../public/assets/anim/lottie-submit-glimpse.json')"
						:autoPlay="true"
					/>
				</div> -->

				<div class="np-flex justify-center">
					<ion-button fill="clear" @click="state.step='glimpse'">Go Back</ion-button>
					<ion-button @click="submit">All Done?</ion-button>
					<div class="center-of-page">
						<ion-spinner v-if="state.submitting"></ion-spinner>
					</div>
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<script setup>

import { defineProps, onMounted, inject, ref, defineExpose, reactive, computed, watch } from 'vue';
import { IonPage, IonContent, IonButton, IonSpinner, IonProgressBar } from '@ionic/vue';
import GlimpseItemRenderer from '@/components/GlimpseItemRenderer.vue';
import { v4 as uuidv4 } from 'uuid';
import { animalNames, adjectives, nouns, capitalizeFirst } from '@/util/nameGenerator.js';
import NPAttachmentImage from '@/components/NPAttachmentImage.vue';

const nameGenIconRef = ref(null);
const api = inject('api');
const props = defineProps({
	publicId: String,
});

const state = reactive({
	disabled: false,
	glimpseAdministration: {},
	found: false,
	notFoundMessage: '',
	loaded: false,
	submitting: false,
	activeItemIndex: 0,
	uuid: null,
	step: 'begin',
	displayName: '',
	responses: {},
	submitted: false,
	nameGenIconDeg: 0,
});

watch(
	() => state.activeItemIndex,
	(newVal) => {
		state.disabled = true
		setTimeout(() => {
			state.disabled = false
		}, 400);
	},
)

const isDebug = computed(() => {
	return window.location.href.indexOf('debug') > -1;
})

const generateName = () => {
	setTimeout(() => {
		const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
		const animal = animalNames[Math.floor(Math.random() * animalNames.length)];
		state.displayName = `${capitalizeFirst(adjective)} ${capitalizeFirst(animal)}`;
	}, 175);
	state.nameGenIconDeg += 360;
	if(nameGenIconRef.value) nameGenIconRef.value.style.transform = `rotate(${state.nameGenIconDeg}deg)`;
}

const title = computed(() => {
	if (!state.glimpseAdministration?.name) return '';
	return state.glimpseAdministration.name;
});

const description = computed(() => {
	if (!state.glimpseAdministration?.description) return '';
	return state.glimpseAdministration.description.replace(/\n/g, "<br/>");
});

const surveyItemLinks = computed(() => {
	if (!state.glimpseAdministration?.surveyItemLinks) return [];
	return state.glimpseAdministration.surveyItemLinks;
});


const activeItem = computed(() => {
	if (!state.glimpseAdministration?.surveyItemLinks) return false;

	return state.glimpseAdministration.surveyItemLinks[state.activeItemIndex].surveyItem;
});

const activeItemLink = computed(() => {
	if (!state.glimpseAdministration?.surveyItemLinks) return false;

	return state.glimpseAdministration.surveyItemLinks[state.activeItemIndex];
});

const activeItemResponseValues = computed(() => {
	const response = state.responses?.[activeItemLink.value.id]
	if(!response) return []

	if(activeItem.value.survey_item_type_id == 1) {
		return [response.text || '']
	} else if(Array.isArray(response)) {
		return response.map((r) => r.survey_item_option_id)
	} else {
		return [activeItem.value.options.find((o) => o.id == response.survey_item_option_id)?.id].filter(Boolean)
	}
})

const storageKey = computed(() => {
	return `glimpse_${props.publicId}_uuid`;
});

const verifyGlimpse = async () => {
	const response = await api.get(`/check/${props.publicId}`)
		.then((response) => {
			state.glimpseAdministration = response.data;
			state.found = true;
			state.loaded = true;
			console.log('found glimpse', state.glimpseAdministration)
			sortOptions()
		})
		.catch((error) => {
			console.log('error',error)
			state.found = false
			state.notFoundMessage = error.response.data.message
			state.loaded = true
			return
		})

}

function sortOptions() {
	state.glimpseAdministration.surveyItemLinks.forEach((link) => {
		if (link.surveyItem.options) {
			link.surveyItem.options.sort((a, b) => {
				if (a.position === b.position) {
					return new Date(a.created_at) - new Date(b.created_at);
				}
				return a.position - b.position;
			})
		}
	})
}

const beginGlimpse = () => {
	const uuid = uuidv4();
	localStorage.setItem(storageKey.value, JSON.stringify({
		uuid: uuid,
		displayName: state.displayName,
		submitted: false
	}));
	state.uuid = uuid;
	state.step = 'glimpse';
}

const setLocalStorage = () => {
	// check if the localStorageKey exists in localStorage
	if (localStorage.getItem(storageKey.value)) {
		state.step = 'glimpse'
		const store = localStorage.getItem(storageKey.value);

		if (!store) {
			console.log('No local storage found')
			return
		}

		let json = JSON.parse(store)
		state.uuid = json.uuid
		state.displayName = json.displayName

		state.submitted = json.submitted

		if (json.responses) {
			state.responses = json.responses

			let found = false
			// go the last item that was completed
			state.glimpseAdministration.surveyItemLinks.forEach((l, i) => {

				if (!json.responses[l.id] && !found) {
					state.activeItemIndex = i;
					found = true;
					return
				}
			})
		}
	}
}

const setLocalStorageSubmitted = () => {
	if (!localStorage.getItem(storageKey.value)) return;

	let store = localStorage.getItem(storageKey.value);
	let json = JSON.parse(store);
	if (!json.submitted) json.submitted = true;

	localStorage.setItem(storageKey.value, JSON.stringify(json));
}

const setResponseInLocalStorage = (response) => {
	if (!localStorage.getItem(storageKey.value)) return;

	let store = localStorage.getItem(storageKey.value);
	let json = JSON.parse(store);

	if (!json.responses) json.responses = {};

	json.responses[activeItemLink.value.id] = response;

	// update localStorage
	localStorage.setItem(storageKey.value, JSON.stringify(json));
}

onMounted(async () => {
	if (!props.publicId) return;

	await verifyGlimpse()
	setLocalStorage();
	generateName()
	// load()
})

const submit = async () => {
	state.submitting = true;
	const resp = await api.post("/submit", {
		publicId: props.publicId,
		userSessionId: state.uuid,
		displayName: state.displayName,
		submitted: true,
		responses: state.responses
	}).catch(err => {
		console.error(err)
		state.submitting = false;
	})

	state.submitted = true;
	state.submitting = false;
	setLocalStorageSubmitted();
}

const startOver = () => {
	localStorage.removeItem(storageKey.value)
	state.step = 'begin'
	state.submitted = false
	state.responses = {}
	state.activeItemIndex = 0
}

const save = async (opt) => {
	console.log('save', opt);
	let rec = null
	if (Array.isArray(opt)) {
		rec = []
		rec = opt.map((o) => {
			return {
				survey_item_link_id: activeItemLink.value.id,
				survey_administration_id: state.glimpseAdministration.id,
				survey_item_option_id: o.id,
				position: o.position,
				user_session: state.uuid,
			}
		})
	} else {
		rec = {
			survey_item_link_id: activeItemLink.value.id,
			survey_administration_id: state.glimpseAdministration.id,
			survey_item_option_id: activeItem.value.survey_item_type_id != 1 ? opt.id : null,
			text: activeItem.value.survey_item_type_id == 1 ? opt : null,
			user_session: state.uuid,
		}
	}

	console.log('saving',activeItemLink.value.id,rec);

	state.responses[activeItemLink.value.id] = rec

	setResponseInLocalStorage(rec)

	const count = state.glimpseAdministration?.surveyItemLinks.length || 0

	if (state.activeItemIndex < count - 1) {
		state.activeItemIndex++
	} else {
		state.step = 'submit'
	}

}
</script>

<style scoped lang="scss">
// ion-content {
// 	--background: var(--ion-color-step-50);
// }
.glimpse-cover {
	display: flex;
	flex-direction: column;
	background: var(--ion-background-color);
	border-radius: 6px;
	max-width: 900px;
	padding: 16px;
	margin: 0 auto;
	// align-items: center;
}
.glimpse-cover-art {
	height: 256px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	background-image: url(/public/assets/images/np-dog.png);
}
.glimpse-wrapper {
	max-width: 800px;
	margin: 0 auto;
}

.title {
	font-size: 1.5rem;
	font-weight: 500;
	color: var(--ion-text-color);
	text-align: center;
}

.subtitle {
	font-size: 14px;
	font-weight: 400;
	color: var(--ion-text-medium);
	align-self: center;
}

.name-section {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	padding: 12px;
}

.name-container {
	display: flex;
	flex-direction: column;
	background-color: var(--ion-color-step-150);
	width: 255px;
	border-radius: 6px;
	padding: 10px 16px;
}

.anon-name {
	display: flex;
	align-items: center;
	i {
		transition: transform 0.300s ease-in-out;
	}
	i:hover {
		font-weight: 600 !important;
		// transform: rotate(180deg);
	}
}
.anon-name-label {
	font-size: 12px;
	color: var(--ion-text-medium);
}

.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}

.center-of-page {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.prog-bar {
	padding-top: 24px;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 500px;
	margin: 0 auto;
}
.prog-bar-controller {
	display: flex;
	flex-grow: 1;
	align-items: center;
	font-size: 14px;
	color: var(--ion-color-primary);
}

</style>