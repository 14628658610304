<template>
	<div class="ma-4">
		<div class="text-medium px-4 my-3">{{ item.text }}</div>

		<div class="attachment-container">
			<i v-if="item.surveyItemAttachments?.length" class="fas fa-paperclip mr-2" />
			<div class="attachment" v-for="a of item.surveyItemAttachments" :key="a.id" :id="`modal-`+a.id">
				<div class="attachment-icon">
					<i :class="`fas ${fileIcon(a.attachment.type)}`" class="fa-24 mr-2" />
				</div>

				<div class="attachment-text">
					{{ a.attachment.filename }}
				</div>

				<ion-modal :trigger="`modal-`+a.id">
					<NPAttachmentPreview :attachment="a.attachment" />
				</ion-modal>
			</div>
		</div>

		<component :is="getComponentType(item)" @selected="setResponse" :item="item" :values="values" />
	</div>
</template>

<script setup>
import { defineAsyncComponent, defineProps, defineEmits } from "vue"
import NPAttachmentPreview from "./NPAttachmentPreview.vue"
import { IonModal } from "@ionic/vue"
const emit = defineEmits(["selected"])

const props = defineProps({
	item: {
		type: Object,
		required: true
	},
	values: {},
})

// const showAttachment = async (attachment) => {
// 	console.log('attachment', attachment)
// 	console.log('NPAttachmentPreview', NPAttachmentPreview)

// 	const modal = await modalController.create({
// 		component: NPAttachmentPreview,
// 		cssClass: "modal-60",
// 		componentProps: {
// 			attachment: attachment,
// 			header: true,
// 		},
// 	})
// 	modal.present()
// 	await modal.onWillDismiss()
// }

const fileIcon = (type) => {
	if (type.match(/^image\//)) return 'fa-file-image'
	if (type.match(/^application\/pdf/)) return 'fa-file-pdf'
	if (type.match(/^application\/msword/)) return 'fa-file-word'
	if (type.match(/^application\/vnd.openxmlformats-officedocument.wordprocessingml.document/)) return 'fa-file-word'
	if (type.match(/^application\/vnd.ms-excel/)) return 'fa-file-excel'
	if (type.match(/^application\/vnd.openxmlformats-officedocument.spreadsheetml.sheet/)) return 'fa-file-excel'
	if (type.match(/^application\/vnd.ms-powerpoint/)) return 'fa-file-powerpoint'
	if (type.match(/^application\/vnd.openxmlformats-officedocument.presentationml.presentation/)) return 'fa-file-powerpoint'
	return 'fa-file'
}

const getComponentType = () => {
	switch (props.item.survey_item_type_id) {
		case 1:
			return defineAsyncComponent(() => import("./glimpseItems/TextItem.vue"))
		case 2:
			return defineAsyncComponent(() => import("./glimpseItems/ListDropdown.vue"))
		case 3:
			return defineAsyncComponent(() => import("./glimpseItems/MultipleChoiceItem.vue"))
		case 4:
			return defineAsyncComponent(() => import("./glimpseItems/CheckboxItem.vue"))
		case 5:
			return defineAsyncComponent(() => import("./glimpseItems/LinearScale.vue"))
		case 6:
			return defineAsyncComponent(() => import("./glimpseItems/SlidingScale.vue"))
		case 7:
			return defineAsyncComponent(() => import("./glimpseItems/RankSortItem.vue"))
		default:
			return defineAsyncComponent(() => import("./glimpseItems/MultipleChoiceItem.vue"))
	}
}

const setResponse = (option) => {
	emit("selected", option)
}
</script>

<style lang="scss" scoped>
.modal-60 { --width: 60%;}
.attachment-container {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
}
.attachment {
	display: flex;
	align-items: center;
	margin: 0 4px;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: #eee;
	}

	&-icon {
		text-align: center;
		padding: 8px;
		background-color: var(--ion-color-primary);
		color: white;
		font-size: 24px;
	}

	&-text {
		padding: 8px;
		font-size: 12px;
	}
}
</style>
