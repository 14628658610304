import { createRouter, createWebHistory } from "@ionic/vue-router"
import GlimpseHome from "./views/GlimpseHome.vue"

const routes = [
	{
		path: "/:publicId",
		component: GlimpseHome,
		props: true,
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router
