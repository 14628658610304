<template>
	<ion-page class="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-400 to-blue-600">
		<ion-content>
			<router-view />
		</ion-content>
		<ion-footer>
			<ion-toolbar>
				<div class="ion-text-right nplogo clickable" @click="openTab">
					<span class="text-xs">Powered by</span>
				</div>
			</ion-toolbar>
		</ion-footer>
	</ion-page>
</template>

<script>
import { IonPage, IonContent, IonFooter, IonToolbar } from "@ionic/vue"
export default {
	name: "App",
	components: { IonPage, IonContent, IonFooter, IonToolbar },
	data() {
		return {}
	},
	mounted() {
	},
	methods: {
		openTab() {
			// open a new tab to https://nextpath.education
			window.open("https://nextpath.education", "_blank")
		}
	},
}
</script>

<!-- <style scoped lang="scss">
ion-toolbar { --background: var(--ion-color-step-50); }
</style> -->

<style lang="scss">
.text-color {
    color: var(--ion-text-color);
}
.text-medium {
    color: var(--ion-text-medium);
}
.np-flex {
	display: flex;
}

.justify-center {
	justify-content: center;
}

.flex-grow-1 {
    flex-grow: 1;
}

.nplogo {
	height: 32px;
	margin-right: 32px;
	padding-right: 48px;
	line-height: 32px;
	vertical-align: middle;
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;
	background-image: url(/public/assets/images/nplogo.png);
}

$fontSizes: ("10": 10px, "12": 12px, "14": 14px, "16": 16px, "18": 18px, "20": 20px, "22": 22px, "24": 24px, "26": 26px, "48": 48px);
$padding:("1": 2px, "2": 4px, "3": 8px, "4": 16px, "5": 32px, "6": 64px, "a": auto);

@each $size, $px in $fontSizes {
    .fs-#{$size} {
        font-size: $px !important;
    }
}

@each $step, $px in $padding {
    .mt-#{$step} {
        margin-top: $px;
    }

    .mr-#{$step} {
        margin-right: $px;
    }

    .mb-#{$step} {
        margin-bottom: $px;
    }

    .ml-#{$step} {
        margin-left: $px;
    }

    .ma-#{$step} {
        margin: $px $px $px $px;
    }

    .mx-#{$step} {
        margin-left: $px;
        margin-right: $px;
    }

    .my-#{$step} {
        margin-top: $px;
        margin-bottom: $px;
    }

    .pt-#{$step} {
        padding-top: $px;
    }

    .pr-#{$step} {
        padding-right: $px;
    }

    .pb-#{$step} {
        padding-bottom: $px;
    }

    .pl-#{$step} {
        padding-left: $px;
    }

    .pa-#{$step} {
        padding: $px $px $px $px;
    }

    .px-#{$step} {
        padding-left: $px;
        padding-right: $px;
    }

    .py-#{$step} {
        padding-top: $px;
        padding-bottom: $px;
    }
}

.clickable {
	cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.grow-enter-active,
.grow-leave-active {
  transition: height 0.5s ease;
}

.grow-enter-from,
.grow-leave-to {
  height: 0px;
}

// slide in / out
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}

.xlist-move,
.xlist-enter-active,
.xlist-leave-active {
  // transition: all 0.5s ease;
  transition: all 0.5s cubic-bezier(.47, 1.64, .41, .8);
}

.xlist-enter-from,
.xlist-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.xlist-leave-active {
  position: absolute;
}
</style>
