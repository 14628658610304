<template>
	<ion-header v-if="header">
		<ion-toolbar>
			<ion-title>Preview</ion-title>
			<ion-buttons slot="end">
				<ion-button @click="modalController.dismiss">Close</ion-button>
			</ion-buttons>
		</ion-toolbar>
	</ion-header>

	<ion-content>
		<div class="file-preview">
			<img v-if="isImage" :src="fileUrl" :alt="fileName" class="preview-image" />
			<video v-else-if="isVideo" :src="fileUrl" controls class="preview-video" />
			<pre v-else-if="isText" class="preview-text">{{ fileContent }}</pre>
			<iframe v-else-if="isOffice" :src="officeViewerUrl" frameborder="0" class="preview-office" />
			<iframe v-else-if="isPDF" ref="pdfIframe" :src="pdfViewerUrl" frameborder="0" width="100%" height="100%" />
	
			<div v-else class="unsupported-file">Unsupported file type</div>
		</div>
	</ion-content>
</template>

<script setup>
import { ref, computed, onUnmounted, defineProps, defineEmits, inject, onMounted } from 'vue'
import { modalController } from '@ionic/vue';
import { IonHeader, IonTitle, IonButtons, IonButton, IonToolbar, IonContent } from '@ionic/vue';

const np = inject('np')

const props = defineProps({
	attachment: {
		type: Object,
		required: false,
		// default: () => ({ url: '', filename: '' }),
	},
	header: {
		type: Boolean,
		default: false,
	}
})

const emit = defineEmits(['dismiss'])

onMounted(() => {
	console.log('props.attachment', props.attachment)
})

const item = ref({ url: '', filename: '' })
const fileUrl = ref(null)
const fileContent = ref(null)

const isImage = computed(() => /\.(jpg|jpeg|png|gif)$/i.test(item.value.filename))
const isVideo = computed(() => /\.(mp4|webm|ogg)$/i.test(item.value.filename))
const isText = computed(() => /\.(txt|md|html|csv|json)$/i.test(item.value.filename))
const isPDF = computed(() => /\.(pdf)$/i.test(item.value.filename))
const isOffice = computed(() => /\.(doc|docx|xls|xlsx|ppt|pptx)$/i.test(item.value.filename))

const fileName = computed(() => item.value.filename)

const officeViewerUrl = computed(() => {
	if (isOffice.value) {
		const officeViewerBase = 'https://view.officeapps.live.com/op/embed.aspx?src='
		return officeViewerBase + encodeURIComponent(item.value.url)
	}
	return null
})

const pdfViewerUrl = computed(() => {
	if (isPDF.value) {
		return 'https://mozilla.github.io/pdf.js/web/viewer.html?file=' + encodeURIComponent(item.value.url)
	}
	return null
})

getAttachmentInfo()

async function getAttachmentInfo() {
	console.log('getAttachmentInfo', props.attachment)
	if (props.attachment?.url && props.attachment?.filename) {
		item.value = props.attachment
		await loadFile()

	} else {
		console.error('No attachment id or url provided:')
		fileUrl.value = null
		fileContent.value = null
	}
}

async function loadFile() {
	if (isImage.value || isVideo.value) {
		fileUrl.value = item.value.url
	} else if (isText.value) {
		try {
			const response = await fetch(item.value.url)
			const content = await response.text()
			fileContent.value = content
		} catch (error) {
			console.error('Error fetching text file:', error)
		}
	}
}

onUnmounted(() => {
	if (fileUrl.value && !isOffice.value) {
		URL.revokeObjectURL(fileUrl.value)
	}
})


</script>

<style scoped>
button {
	padding: 0.5rem 1rem;
	margin: 0.5rem;
}

.file-preview {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.preview-image,
.preview-video {
	padding: 0px 16px 16px 16px;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.preview-text {
	white-space: pre-wrap;
	word-wrap: break-word;
	max-width: 100%;
	max-height: 100%;
	overflow: auto;
}

.preview-pdf {
	/* display: flex;
	justify-content: center;
	align-items: center; */
	width: 100%;
	height: 100%;
	overflow-y: auto;

	max-width: 100%;
	max-height: 100%;
}

.preview-office {
	width: 100%;
	height: 100%;
	border: none;
}

.unsupported-file {
	font-size: 1.5rem;
	text-align: center;
}
</style>
