<template>
    <Lazy>
        <div :style="style" />
    </Lazy>
</template>

<script setup>
import { inject, computed, ref, watchEffect, defineProps } from "vue"
import Lazy from '@/components/Lazy.vue'


const np = inject('np')
const attachment = ref(null)

const props = defineProps({
    attachment: {
        type: Object,
        required: true,
    },
    width: {
        type: String,
        default: '64px',
    },
    height: {
        type: String,
        default: '64px',
    }
})

watchEffect(async () => {
    if (props.attachment) {
        if (props.attachment.url) {
            attachment.value = props.attachment
        } else {
            const resp = await np.api.get({
                url: `/attachment/${props.attachment.id}/url/${props.attachment.key}`
            })
            attachment.value = resp
        }
    }
})

const style = computed(() => {
    return {
        minWidth: props.width,
        minHeight: props.height,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
		backgroundImage: `url(${attachment.value?.url || ''})`,
    }
})

</script>