import { createApp } from 'vue'
import { IonicVue, getPlatforms } from '@ionic/vue'
import App from './App.vue'
import router from "./router"
import './assets/tailwind.css'
import axios from "axios"
import LottieAnimation from "lottie-web-vue"
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import "./theme/variables.scss"
// Globally register all common NP Components



console.log('NODE_ENV', process.env.NODE_ENV)
const axiosInstance = axios.create({
	baseURL: process.env.NODE_ENV === "development" ? "http://localhost:3001/public-api" : "https://public.nextpath.app/public-api",
	headers: {
		"Content-Type": "application/json",
	},
	withCredentials: true,
})

console.log('process.env.VUE_APP_BASE_URL', process.env.VUE_APP_BASE_URL)

const app = createApp(App)
	.provide("api", axiosInstance)
	.provide("np", {
		isDesktop: getPlatforms().indexOf("desktop") != -1,
	})
	.use(IonicVue)
	.use(router)
	.use(LottieAnimation)


app.directive("disable-all", {
	mounted(el, binding) {
		if (binding.value) {
			el.style.pointerEvents = "none";
			el.style.opacity = "0.8";
		}
	},
	updated(el, binding) {
		if (binding.value) {
			el.style.pointerEvents = "none";
			el.style.opacity = "0.8";
		} else {
			el.style.pointerEvents = "auto";
			el.style.opacity = "1";
		}
	},
});


router.isReady().then(() => {
	app.mount("#app")
})
